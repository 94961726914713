export enum LandingPageType {
    // 0
    Default = 'Default',
    // 1
    NoInventoryFinanceCash = 'NoInventoryFinanceCash',
    // 2
    NoInventoryLeaseWalkaway = 'NoInventoryLeaseWalkaway',
    // 3
    NoInventoryPreorder = 'NoInventoryPreorder',
    // 4
    NoInventoryLeasePreOrder = 'NoInventoryLeasePreOrder',
    // 5
    NoInventoryService = 'NoInventoryService',
    // 98, obsolete
    NoInventoryLease = 'NoInventoryLease',
    // 99, obsolete
    NoInventoryRemaining = 'NoInventoryRemaining',
}
