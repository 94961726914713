import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Spinner, Container, Row, Col } from 'react-bootstrap';
import { UnsubscribePageModel } from '../../abstracts/UnsubscribePageModel';
import { getUnsubscribePage } from '../../api/Api';
import AppHeader from '../shared-components/AppHeader';
import UnsubscribeForm from './UnsubscribeForm';
import './UnsubscribePage.css';

const UnsubscribePage: FC = () => {
    document.title = 'Unsubscribe from email marketing campaigns.';
    const query = new URLSearchParams(useLocation()?.search);
    const queryString = query.get('id') || '';
    const [id, setId] = useState(queryString);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [unsubscribePageModel, setUnsubscribePageModel] = useState<UnsubscribePageModel | null>(null);

    useEffect(() => {
        async function initiateUnsubscribePage() {
            await getUnsubscribePage(id).then((res) => {
                if (!res.isError) {
                    setUnsubscribePageModel(res.resource as UnsubscribePageModel);
                } else {
                    setIsError(true);
                    setErrorMessage('Your request cannot be processed.');
                }
                setIsLoaded(true);
            });
        }
        initiateUnsubscribePage();
    }, []);

    return (
        <Container fluid className="unsubscribe-page" data-testid="unsubscribe-page">
            {!isLoaded && (
                <Row>
                    <Col>
                        <Spinner animation="border" role="status" variant="secondary">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </Col>
                </Row>
            )}
            {isLoaded && isError && (
                <Row>
                    <Col>
                        <div className="alert alert-danger" role="alert">
                            {errorMessage}
                        </div>
                    </Col>
                </Row>
            )}
            {isLoaded && !isError && unsubscribePageModel && (
                <Row>
                    <Col>
                        {/* AppHeader */}
                        <Row>
                            <Col>
                                <AppHeader {...unsubscribePageModel.dealerProfile} />
                            </Col>
                        </Row>
                        {/* Form Content */}
                        <Row>
                            <Col>
                                <UnsubscribeForm id={id} model={unsubscribePageModel} />
                            </Col>
                        </Row>
                        {/* Footer */}
                        <Row>
                            <Col>
                                <p className="text-center p-5">
                                    Your {unsubscribePageModel.dealerProfile.dealerName} Team
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
        </Container>
    );
};

export default UnsubscribePage;
