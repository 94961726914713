import React, { FC } from 'react';
import { LandingPageType } from '../../abstracts/enums/LandingPageType';
import { LandingPageModel } from '../../abstracts/LandingPageModel';
import DefaultLandingPage from './DefaultLandingPage';

const LandingPageContent: FC<LandingPageModel> = (props) => {
    const dealerName = props.dealerProfile.dealerName;

    let content = <p>We appreciate you reaching out. A representative from {dealerName} will contact you shortly.</p>;

    const landingPageTypeName: string = props.landingPageType.toString();

    // select landing page message
    switch (landingPageTypeName) {
        case LandingPageType.NoInventoryFinanceCash:
            content = (
                <p>
                    We have received your request for a complimentary appraisal. A representative from {dealerName} will
                    contact you soon to schedule your appraisal, so we can confirm the value of your vehicle and discuss
                    your available options.
                </p>
            );
            break;
        case LandingPageType.NoInventoryLeasePreOrder:
            content = (
                <p>
                    We have received your request to learn more about reserving your next{' '}
                    {props.dealerProfile.brandName}. A {dealerName} team member will contact you soon to begin the
                    process and help you pre-order your new vehicle.
                </p>
            );
            break;
        case LandingPageType.NoInventoryLeaseWalkaway:
            content = (
                <p>
                    We have received your request for a complimentary appraisal. A representative from {dealerName} will
                    contact you soon to discuss the unique opportunity you may have to walk away from your current lease
                    early, without worrying about your remaining payments.
                </p>
            );
            break;
        case LandingPageType.NoInventoryPreorder:
            content = (
                <p>
                    We have received your request to learn more about pre-ordering a new car. A representative from{' '}
                    {dealerName} will contact you soon to begin the process and help you build your ideal vehicle.
                </p>
            );
            break;
        case LandingPageType.NoInventoryService:
            content = (
                <p>
                    We have received your request for a service appointment. A representative from {dealerName} will
                    contact you soon to set up a convenient date and time.
                </p>
            );
            break;
        case LandingPageType.Default:
        default:
            content = <DefaultLandingPage {...props} />;
            break;
    }

    return content;
};

export default LandingPageContent;
