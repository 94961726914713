import React, { FC } from 'react';
import classes from './BrandImage.module.css';

type BrandImageProps = {
    brand: string;
};

const BrandImage: FC<BrandImageProps> = (props) => {
    return (
        <div data-name={props.brand} className={`${classes.logo} ${classes[props.brand]}`} data-testid="brand-image" />
    );
};

export default BrandImage;
