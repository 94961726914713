import React, { FC, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { getCampaignPdf } from '../../api/Api';
import Config from '../../Config';
import './CampaignPdf.css';

const BaseUrl = Config.API_URL || 'http://localhost';

const CampaignPdf: FC = () => {
    const query = new URLSearchParams(useLocation()?.search);
    //  options /Mailer/Download/:id or /Mailer/Download?id=
    let queryString = query.get('id');
    const { encryptedString } = (useParams() as { encryptedString: string }) || '';
    queryString = queryString || encryptedString;

    const [isLoaded, setIsLoaded] = useState(false);
    const [id, setId] = useState(queryString);
    const [byteArray, SetByteArray] = useState<string>('');
    const [loadCampaignPdf, SetHasCampaignPdf] = useState(false);
    const iframe_url = `${BaseUrl}/mailer/download?id=${id}`;

    useEffect(() => {
        async function initiatePdfPage() {
            window.location.href = iframe_url;
        }

        initiatePdfPage();
    }, []);
    return (
        <div className="campaign-pdf text-center" data-testid="campaign-pdf">
            {!isLoaded ? (
                <span></span>
            ) : loadCampaignPdf ? (
                <object data={byteArray} type="application/pdf" className="frame-pdf">
                    {/* to handle android and ios */}
                    <iframe width="100%" height="100%" src={iframe_url} />
                </object>
            ) : (
                <div>
                    <div className="error-text">
                        <h1>Offer expired</h1>
                        <p>We are sorry, this offer expired already.</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CampaignPdf;
