import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Row } from 'react-bootstrap';

const CamapignPdfError: FC = () => {
    const location = useLocation();
    const [isExpiredPromotion, setExpiredPromotion] = useState<boolean>(false);

    useEffect(() => {
        const query = new URLSearchParams(location?.search);
        const reason = query.get('reason') || '';

        switch (reason.toLowerCase()) {
            case '2209':
                setExpiredPromotion(true);
                document.title = 'Offer expired';
                break;
            default:
                document.title = 'Something went wrong!';
        }
    }, [location]);

    return (
        <Row>
            {isExpiredPromotion && (
                <div className="alert alert-danger text-center">
                    <h5>Sorry, this offer has expired.</h5>
                </div>
            )}
            {!isExpiredPromotion && (
                <div className="alert alert-danger text-center">
                    <h5>Sorry; something went wrong.</h5>
                </div>
            )}
        </Row>
    );
};

export default CamapignPdfError;
