import React, { FC, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { routes } from '../AppRoutes';
import { ListGroup, Row, Col, FormControl, InputGroup, Button, Badge, DropdownButton, Dropdown } from 'react-bootstrap';
import Config from '../Config';
import TeamLogo from './TeamLogo';

import '../App.css';

const RouterTester: FC = () => {
    const environment = Config.ENVIRONMENT || 'local';
    const apiUrl = Config.API_URL || 'http://localhost';
    document.title = `Test Page ${environment}`;

    const [routePath, setRoutePath] = useState<string>(routes[0].path);
    const [params, setParams] = useState<string>('');
    const history = useHistory();

    const addParams = (route: string) => {
        return `${route}?id=encryptedStringOfSomething`;
    };

    const handleOnSelectChange = (eventKey: string | null) => {
        const route = eventKey || '';
        setRoutePath(route);
    };

    const goToUrl = () => {
        history.push(`${routePath}?id=${params}`);
    };

    const openSwaggerUrl = () => {
        window.open(`${apiUrl}/swagger/index.html`, '_blank');
    };

    return (
        <Row data-testid="router-tester">
            <Col lg="1" md="1">
                <TeamLogo />
            </Col>
            <Col lg="11" md="11">
                <Row>
                    <Col md="10" lg="10">
                        <h2>
                            Test Page <Badge bg="dark"> {environment}</Badge>{' '}
                        </h2>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col md="10" lg="10">
                        <h5>Available Routes</h5>
                        <ListGroup horizontal>
                            {routes.map((route, index) => {
                                return (
                                    <ListGroup.Item key={index} variant="primary">
                                        {' '}
                                        <Link to={addParams(route.path)}>{route.name}</Link>{' '}
                                    </ListGroup.Item>
                                );
                            })}
                        </ListGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="10" lg="10">
                        &nbsp;
                    </Col>
                </Row>
                <Row>
                    <Col md="10" lg="10">
                        <h5>Test by Encrypted String</h5>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="inputGroup-sizing-sm">{window.location.host}</InputGroup.Text>
                            <DropdownButton
                                variant="outline-dark"
                                title={routePath}
                                defaultValue={routePath}
                                size="sm"
                                onSelect={handleOnSelectChange}
                            >
                                {routes.map((route) => {
                                    return (
                                        <Dropdown.Item value={route.path} key={route.path} eventKey={route.path}>
                                            {route.name}
                                        </Dropdown.Item>
                                    );
                                })}
                            </DropdownButton>
                            <InputGroup.Text id="basic-addon2">id=</InputGroup.Text>
                            <FormControl
                                size="sm"
                                aria-label="parameters"
                                placeholder="encrypted string"
                                aria-describedby="basic-addon1"
                                value={params}
                                onChange={(e) => setParams(e.target.value)}
                            />
                            <Button variant="outline-dark" onClick={goToUrl}>
                                Go
                            </Button>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="10" lg="10">
                        <h5>Api Test Page</h5>
                        <Button variant="primary" onClick={openSwaggerUrl}>
                            Swagger
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default RouterTester;
