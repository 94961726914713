import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { postTrackingClick } from '../../api/Api';
import Config from '../../Config';

const TrackingClick: FC = () => {
    const query = new URLSearchParams(useLocation()?.search);
    const id = query.get('id') || '';

    useEffect(() => {
        async function initiateTrackingPage() {
            await postTrackingClick(id).then((res) => {
                let navigateTo;
                if (!res.isError) {
                    navigateTo = res?.resource?.redirectUri || '';
                } else {
                    const status = res?.status || '';
                    const reasonCode = res?.resource?.reasonCode || '';
                    const errorMsg = res?.errorMessage || '';
                    const retryCount = res?.retryCount || '';
                    navigateTo = `/error?reason=${reasonCode}&status=${status}&notify=true`;

                    if (Config.SHOW_ERROR) {
                        navigateTo += `&er=${errorMsg}&rc=${retryCount}`;
                    }
                }

                window.location.href = navigateTo;
            });
        }

        initiateTrackingPage();
    }, [id]);

    return (
        <div data-testid="tracking-click" className="text-center p-5">
            <Spinner animation="border" variant="secondary" role="status" style={{ width: '4em', height: '4em' }}>
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    );
};

export default TrackingClick;
