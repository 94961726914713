import React, { FC, useState } from 'react';
import { Button, Form, FormGroup, Row, Col } from 'react-bootstrap';
import { CampaignRequest } from '../../abstracts/CampaignRequest';
import { UnsubscribePageModel } from '../../abstracts/UnsubscribePageModel';
import { UnsubscribeType } from '../../abstracts/enums/UnsubscribeType';
import { postUnsubscribePage } from '../../api/Api';
import './UnsubscribePage.css';

interface Props {
    id: string;
    model?: UnsubscribePageModel;
}

const UnsubscribeForm: FC<Props> = ({ id, model }) => {
    const dealerName = model?.dealerProfile.dealerName || '';
    const [unsubscribeChoice, setUnsubscribeChoice] = useState<UnsubscribeType>(
        UnsubscribeType.DoNotUnsubscribe.valueOf(),
    );
    const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
    const [confirmErrorMessage, setConfirmErrorMessage] = useState<string | null>(null);

    // form options and labels
    const unsubscribeFormOptions = [
        {
            id: 'unsubscribeAm',
            value: UnsubscribeType.UnsubscribeAm.valueOf(),
            label: 'Please unsubscribe me from similar upgrade offers.',
        },
        {
            id: 'unsubscribeDealerAndAm',
            value: UnsubscribeType.UnsubscribeDealerAndAm.valueOf(),
            label: `Please unsubscribe me from all ${dealerName} marketing emails (including event invitations and promotions).`,
        },
        {
            id: 'unsubscribeDealerAllAndAm',
            value: UnsubscribeType.UnsubscribeDealerAllAndAm.valueOf(),
            label: `Please unsubscribe me from ALL ${dealerName} emails (including service notifications, recall information, etc).`,
        },
        {
            id: 'doNotUnsubscribe',
            value: UnsubscribeType.DoNotUnsubscribe.valueOf(),
            label: `I've changed my mind. Please keep me subscribed to ${dealerName} emails.`,
        },
    ];

    if (model?.hideOption3ForDealer === true) {
        unsubscribeFormOptions.splice(2, 1);
    }

    // on radio change
    const onRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        const value = parseInt(event.target.value);
        setUnsubscribeChoice(value);
    };

    // submit selection
    const onSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        // submit choice
        const campaignRequest: CampaignRequest = {
            id: id,
            unsubscribeType: unsubscribeChoice,
        };

        await postUnsubscribePage(campaignRequest).then((res) => {
            if (!res.isError) {
                document.title = 'Thank You.';
            } else {
                setConfirmErrorMessage('Your request cannot be processed.');
            }
            setIsConfirmed(true);
        });
    };

    return (
        <>
            {/* Form */}
            {!isConfirmed && (
                <>
                    <Row data-testid="unsubscribe-form">
                        <Col>
                            <p>
                                You are currently subscribed to the {dealerName} email as: <br />
                                <strong>{model?.email}</strong>
                            </p>
                            <br />
                            <p className="Unsubscribe-warning-text">Are you sure you want to unsubscribe?</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>
                                By unsubscribing, you will stop receiving free emails regarding customized upgrade
                                offers, exclusive benefits such as special event invitations, and first-hand information
                                from {dealerName}.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form onSubmit={onSubmit}>
                                <FormGroup
                                    className="mt-3 mb-4"
                                    controlId="formUnsubscribeOptions"
                                    key={'unsubscribeType'}
                                >
                                    {unsubscribeFormOptions.map((option) => (
                                        <Form.Check
                                            key={option.id}
                                            name="unsubscribeTypeGroup"
                                            type="radio"
                                            id={option.id}
                                            value={option.value}
                                            label={option.label}
                                            defaultChecked={unsubscribeChoice === option.value}
                                            onChange={onRadioChange}
                                        />
                                    ))}
                                </FormGroup>
                                <Button variant="outline-dark" type="submit">
                                    Submit my choice
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </>
            )}

            {/* Confirmed with error */}
            {isConfirmed && confirmErrorMessage && (
                <Row>
                    <Col className="alert alert-danger" role="alert">
                        {confirmErrorMessage}
                    </Col>
                </Row>
            )}

            {/* Confirmed */}
            {isConfirmed && !confirmErrorMessage && (
                <Row>
                    <Col>
                        {unsubscribeChoice !== UnsubscribeType.DoNotUnsubscribe.valueOf() ? (
                            <div>
                                <br />
                                <h2>Thank You!</h2>
                                <br />
                                <p>
                                    We have received your request to be removed from future email communications and we
                                    are sorry to see you go.
                                    <br />
                                    Your opt-out will be processed within 10 days, in compliance with the CAN SPAM Act
                                    of 2003 (the
                                    {'Act'}).
                                </p>
                            </div>
                        ) : (
                            <div>
                                <br />
                                <h2>Thank you for your loyalty!</h2>
                                <br />
                                <p>
                                    Your request will be processed as soon as possible. You will not miss one of our new
                                    outstanding personalized offers, as well as exclusive benefits such as special event
                                    invitations, and first-hand information from{' '}
                                    <b>{model?.dealerProfile.dealerName}</b>.
                                </p>
                                <p>We appreciate your ongoing interest in our dealership.</p>
                            </div>
                        )}
                    </Col>
                </Row>
            )}
        </>
    );
};

export default UnsubscribeForm;
