const envSettings = window as any;

class Config {
    static API_URL = envSettings.Configs?.API_URL;
    static SHOW_TEST = envSettings.Configs?.SHOW_TEST;
    static ENVIRONMENT = envSettings.Configs?.ENVIRONMENT;
    static SHOW_ERROR = envSettings.Configs?.SHOW_ERROR;
}

export default Config;
