import React, { FC } from 'react';
import { Toaster } from 'react-hot-toast';

export const App: FC = (props) => {
    return (
        <div data-testid="app" className="app">
            <Toaster reverseOrder={false} />
            {props.children}
        </div>
    );
};
