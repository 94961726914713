export enum UnsubscribeType {
    // The user is unsubscribed from only aM emails
    UnsubscribeAm = 0,

    // The user is unsubscribed from all aM emails and all dealer marketing emails
    UnsubscribeDealerAndAm = 1,

    // The user is unsubscribed from all aM emails and all dealer emails
    UnsubscribeDealerAllAndAm = 2,

    // The user is not unsubscribed from any emails
    DoNotUnsubscribe = 3,
}
