import { FetchRetry } from './fetch-retry';
import Config from '../Config';

let BaseUrl = Config.API_URL || 'http://localhost';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export type ApiResponse<T> = {
    status: number;
    isError: boolean;
    errorMessage?: string;
    retryCount?: number;
    resource?: T;
};

export type ApiBinaryResponse = {
    status: number;
    isError: boolean;
    errorMessage?: string;
    resource?: ArrayBuffer;
};

async function fetch<T extends object>(
    url: string,
    method: HttpMethod,
    body?: T,
    headers = new Headers({ Accept: 'application/json' }),
    retry = false,
): Promise<Response> {
    const _url = BaseUrl + url;
    const init = {
        method,
        headers: headers,
        body: body ? JSON.stringify(body) : undefined,
    };

    if (!retry) {
        return await window.fetch(_url, init);
    } else {
        return await FetchRetry(_url, init);
    }
}

async function fetchAndUnwrap<TBody extends object, TReturn>(
    method: HttpMethod,
    url: string,
    body?: TBody,
): Promise<ApiResponse<TReturn>> {
    try {
        const response = await fetch(url, method, body);

        const apiResponse: ApiResponse<TReturn> = {
            status: response.status,
            isError: !response.ok,
        };

        if (!response.ok) {
            apiResponse.errorMessage = await response.text();
        } else if (response.status !== 204) {
            apiResponse.resource = await response.json();
        }

        return apiResponse;
    } catch (error) {
        return <ApiResponse<TReturn>>{
            isError: true,
            errorMessage: getErrorMessage(error),
        };
    }
}

async function fetchAndUnwrapBinary<TBody extends object>(
    method: HttpMethod,
    url: string,
    body?: TBody,
): Promise<ApiBinaryResponse> {
    try {
        const response = await fetch(url, method, body);

        const apiResponse: ApiBinaryResponse = {
            status: response.status,
            isError: !response.ok,
        };

        if (!response.ok) {
            apiResponse.errorMessage = await response.text();
        } else if (response.status !== 204) {
            apiResponse.resource = await response.arrayBuffer();
        }

        return apiResponse;
    } catch (error) {
        return <ApiBinaryResponse>{
            isError: true,
            errorMessage: getErrorMessage(error),
        };
    }
}

async function postWithBody<TBody extends object, TReturn>(
    method: HttpMethod,
    url: string,
    body?: TBody,
    retry = false,
): Promise<ApiResponse<TReturn>> {
    try {
        const headers = new Headers({ Accept: 'application/json', 'Content-Type': 'application/json' });
        const response = await fetch(url, method, body, headers, retry);

        const apiResponse: ApiResponse<TReturn> = {
            status: response.status,
            isError: !response.ok,
        };

        const urlParams = new URLSearchParams(response.url);
        if (urlParams.has('retryCount')) {
            apiResponse.retryCount = Number(urlParams.get('retryCount'));
        }

        const badRequest = response.status >= 400 && response.status <= 499;
        const noContent = response.status === 204;

        if (response.ok || (badRequest && !noContent)) {
            apiResponse.resource = await response.json();
        } else {
            apiResponse.resource = undefined;
        }

        return apiResponse;
    } catch (error) {
        return <ApiResponse<TReturn>>{
            isError: true,
            errorMessage: getErrorMessage(error),
            retryCount: error instanceof Error && 'retryCount' in error ? Number(error['retryCount']) : undefined,
        };
    }
}

function getErrorMessage(error: unknown) {
    if (error instanceof Error) return error.message;
    return String(error);
}

export function get<T>(url: string): Promise<ApiResponse<T>> {
    return fetchAndUnwrap('GET', url);
}

export function getBinary(url: string): Promise<ApiBinaryResponse> {
    return fetchAndUnwrapBinary('GET', url);
}

export function post<TBody extends object, TReturn>(
    url: string,
    body?: TBody,
    retry = false,
): Promise<ApiResponse<TReturn>> {
    return postWithBody('POST', url, body, retry);
}

export function put<TBody extends object, TReturn>(url: string, body: TBody): Promise<ApiResponse<TReturn>> {
    return fetchAndUnwrap('PUT', url, body);
}

export function patch<TBody extends object, TReturn>(url: string, body: TBody): Promise<ApiResponse<TReturn>> {
    return fetchAndUnwrap('PATCH', url, body);
}

export function setBaseUrl(baseUrl: string): void {
    BaseUrl = baseUrl;
}
