interface RetryOptions {
    retries?: number;
    retryDelay?: number;
    retryOn?: number[];
}

export async function FetchRetry(url: string, init: any, defaults?: RetryOptions): Promise<any> {
    defaults = defaults || {};
    const baseDefaults = {
        retries: 3,
        retryDelay: 3000,
        retryOn: [500, 503],
    };

    defaults = Object.assign(baseDefaults, defaults);

    return new Promise(function (resolve, reject) {
        const retries = defaults?.retries || 0;
        const retryOn = defaults?.retryOn;

        const wrappedFetch = function (attempt: number) {
            if (attempt > 0) {
                const urlParams = new URLSearchParams(url);
                urlParams.set('retryCount', String(attempt));
                url = decodeURIComponent(urlParams.toString());
            }

            window
                .fetch(url, init)
                .then(function (response) {
                    if (Array.isArray(retryOn) && retryOn.indexOf(response.status) === -1) {
                        resolve(response);
                    } else if (attempt < retries) {
                        retry(attempt, null, response);
                    } else {
                        resolve(response);
                    }
                })
                .catch(function (error) {
                    if (attempt < retries) {
                        retry(attempt, error, null);
                    } else {
                        error.retryCount = attempt;
                        reject(error);
                    }
                });
        };

        function retry(attempt: number, error: any, response: any) {
            const delay = defaults?.retryDelay;
            setTimeout(function () {
                wrappedFetch(++attempt);
            }, delay);
        }

        wrappedFetch(0);
    });
}
