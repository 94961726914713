import React, { FC } from 'react';
import { Row, Col } from 'react-bootstrap';
import BrandImage from './BrandImage';
import DealershipInfoBanner from './DealershipInfoBanner';
import { DealerProfile } from '../../abstracts/DealerProfile';

const AppHeader: FC<DealerProfile> = (props) => {
    return (
        <Row className="App-header pb-3" data-testid="app-header">
            {props.brandCode !== 'OB' && (
                <Col lg={3} md={3} sm={4} xs={4} className="m-0 p-0">
                    <BrandImage brand={props.brandCode} />
                </Col>
            )}
            <Col className="m-0 p-0 h-100">
                <DealershipInfoBanner {...props} />
            </Col>
        </Row>
    );
};

export default AppHeader;
