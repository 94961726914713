import React, { FC } from 'react';
import { LandingPageModel } from '../../abstracts/LandingPageModel';

const DefaultLandingPage: FC<LandingPageModel> = (props) => {
    return (
        <>
            <p data-testid="default-landing-page">
                We appreciate you reaching out. A representative from {props.dealerProfile.dealerName} will contact you
                shortly.
            </p>

            <p>Thank You {props.dealerProfile.dealerName}</p>
        </>
    );
};

export default DefaultLandingPage;
