import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Spinner, Row } from 'react-bootstrap';
import { postTrackingQr } from '../../api/Api';

const TrackingQrScan: FC = () => {
    const query = new URLSearchParams(useLocation()?.search);
    const queryString = query.get('id') || '';

    const [isLoaded, setIsLoaded] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);
    const [id, setId] = useState(queryString);

    useEffect(() => {
        async function initiateTrackingPage() {
            await postTrackingQr(id).then((res) => {
                if (!res.isError) {
                    if (res.resource != undefined) {
                        // redirect to the url returned
                        window.location.href = res.resource.redirectUri;
                    }
                } else {
                    document.title = 'We are sorry, this offer expired already.';
                    setIsInvalid(true);
                }
                setIsLoaded(true);
            });
        }

        initiateTrackingPage();
    }, []);

    return (
        <Row data-testid="tracking-qr-scan">
            {!isLoaded && (
                <Spinner animation="border" role="status" variant="secondary">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            )}
            {isLoaded && isInvalid && (
                <div className="error-text">
                    <h1>Offer expired</h1>
                    <p>We are sorry, this offer expired already.</p>
                </div>
            )}
        </Row>
    );
};

export default TrackingQrScan;
