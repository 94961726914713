import { get, getBinary, post, ApiResponse, ApiBinaryResponse } from './ApiClient';
import { LandingPageModel } from '../abstracts/LandingPageModel';
import { UnsubscribePageModel } from '../abstracts/UnsubscribePageModel';
import { TrackingClickModel } from '../abstracts/TrackingClickModel';
import { CampaignRequest } from '../abstracts/CampaignRequest';

/**
 * Gets the landing page content by given id.
 * @function
 * @param id encryption string with name-value pairs
 * @returns LandingPage doc
 * returns  200 with dealer data, landing page type, logo info
 */
export async function getLandingPage(id: string): Promise<ApiResponse<LandingPageModel>> {
    return await get<LandingPageModel>('/tracking/lp?id=' + id);
}

/**
 * Gets the campaign pdf content by given id.
 * @function
 * @param id encryption string with name-value pairs
 * @returns Pdf
   returns  200 with pdf data
 */
export async function getCampaignPdf(id: string): Promise<ApiBinaryResponse> {
    return await getBinary('/mailer/download?id=' + id);
}

/**
 * Post to track click info to log click. This will return a url to redirect to.
 * @param id encryption string with name-value pairs
 * @returns TrackingClick doc
 * return 200 with redirect url info
 */
export async function postTrackingClick(id: string): Promise<ApiResponse<TrackingClickModel>> {
    return await post('/tracking/click?id=' + id, undefined, true);
}

/**
 * Post to track qr scan info to log click. This will return a url to redirect to.
 * @param id encryption string with name-value pairs
 * @returns TrackingClick doc
 * return 200 with redirect url info
 */
export async function postTrackingQr(id: string): Promise<ApiResponse<TrackingClickModel>> {
    return await post('/tracking/qr?id=' + id);
}

/**
 * Logs an event for customer viewed campaign.
 * @function
 * @param id encryption string with name value pairs
 * @returns http response
 */
export async function postCustomerViewedCampaign(id: string): Promise<ApiResponse<object>> {
    return await post('/tracking/lp?id=' + id);
}

/**
 * Get Unsubscribe Page Info and logging
 * @function
 * @param id encryption string with name value pairs
 * @returns UnsubscribePage doc
 */
export async function getUnsubscribePage(id: string): Promise<ApiResponse<UnsubscribePageModel>> {
    return get<UnsubscribePageModel>('/unsubscribe/confirm?id=' + id);
}

/**
 * Post Submit Unsubscribe Page selection
 * @function
 * @param campaignRequest encryption string id and submit action
 * @returns http response
 */
export async function postUnsubscribePage(campaignRequest: CampaignRequest): Promise<ApiResponse<object>> {
    return await post('/unsubscribe/submit', campaignRequest);
}
