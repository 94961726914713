import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Spinner, Row, Container, Col } from 'react-bootstrap';
import AppHeader from '../shared-components/AppHeader';
import LandingPageContent from './LandingPageContent';
import { getLandingPage, postCustomerViewedCampaign } from '../../api/Api';
import { ApiResponse } from '../../api/ApiClient';
import { LandingPageModel } from '../../abstracts/LandingPageModel';

import './LandingPage.css';

const LandingPage: FC = () => {
    const location = useLocation();
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [landingPageModel, setLandingPageModel] = useState<LandingPageModel | undefined>(undefined);

    // call once onMount
    useEffect(() => {
        document.title = 'Request Received – Thank You!';

        // beware of calling this multiple twice. Each call will be each email to dealer.
        async function initiateLandingPage() {
            // get the encrypted id from the url query string.
            const query = new URLSearchParams(location?.search);
            const id = query.get('id') || '';

            if (id === '') {
                setIsError(true);
                setIsLoaded(true);
                setErrorMessage('Your request cannot be processed because the id was not provided.');

                return;
            }

            postCustomerViewedCampaign(id);

            const response: ApiResponse<LandingPageModel> = await getLandingPage(id);

            if (response.isError) {
                setIsError(true);
                setIsLoaded(true);
                setErrorMessage('There was an error processing your request. Please try again shortly!');

                return;
            }

            setLandingPageModel(response.resource as LandingPageModel);
            setIsLoaded(true);
        }

        initiateLandingPage();
    }, [location]);

    return (
        <Container fluid className="landing-page" data-testid="landing-page">
            {!isLoaded && (
                <Row>
                    <Col>
                        <Spinner animation="border" role="status" variant="secondary">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </Col>
                </Row>
            )}

            {isLoaded && isError && (
                <Row>
                    <Col>
                        <div className="alert alert-danger" role="alert">
                            {errorMessage}
                        </div>
                    </Col>
                </Row>
            )}

            {isLoaded && !isError && landingPageModel !== undefined && (
                <>
                    {/* AppHeader */}
                    <Row className="flex-nowrap">
                        <Col>
                            <AppHeader {...landingPageModel.dealerProfile} />
                        </Col>
                    </Row>
                    {/* Content */}
                    <Row>
                        <Col className="content">
                            <br />
                            <h2>Request Received – Thank You!</h2>
                            {<LandingPageContent {...landingPageModel} />}
                        </Col>
                    </Row>
                    {/* Footer */}
                    <Row>
                        <Col>
                            <p className="text-center p-5">Your {landingPageModel.dealerProfile.dealerName} Team</p>
                        </Col>
                    </Row>
                </>
            )}
        </Container>
    );
};

export default LandingPage;
