import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import './DealershipInfoBanner.css';

type DealershipInfoBannerProps = {
    dealerName: string;
    dealerPhone: string;
    dealerAddress: string;
    dealerCity: string;
    dealerZip: string;
    dealerState: string;
    brandCode: string;
};

const DealershipInfoBanner: FC<DealershipInfoBannerProps> = (props) => {
    return (
        <Row className={`g-0 px-3 py-3 w-100 hright ${props.brandCode}`} data-testid="dealership-info-banner">
            {/* Dealer Address */}
            <Col xs={6} sm={8} md={7} lg={7}>
                <ul className="list-unstyled dealer-info">
                    <li className="dealer-info-bold">{props.dealerName}</li>
                    <li className="dealer-info">{props.dealerAddress}</li>
                    <li className="dealer-info">
                        {props.dealerCity}, {props.dealerState} {props.dealerZip}
                    </li>
                </ul>
            </Col>
            {/* Dealer Phone */}
            <Col xs={6} sm={3} md={5} lg={5} className="text-right">
                <ul className="list-unstyled">
                    <li className="dealer-info-bold">Call: {props.dealerPhone} </li>
                </ul>
            </Col>
        </Row>
    );
};

export default DealershipInfoBanner;
