import React, { FC } from 'react';
import { Figure } from 'react-bootstrap';

import logo from '../bitFire.svg';
import '../App.css';

const TeamLogo: FC = () => {
    return (
        <Figure className="Team-header">
            <Figure.Image src={logo} className="Team-logo" alt="logo" />
            <Figure.Caption>BitFire</Figure.Caption>
        </Figure>
    );
};

export default TeamLogo;
